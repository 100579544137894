import React from 'react';
import { GoDotFill , GoDot } from "react-icons/go";
import samarth from '../images/samarth godara photo.webp';
import tablelogo1 from '../images/table logo 1.webp';

function Portfolio() {
  return (
    <div className='mx-auto md:w-[90%] w-[100%] md:mt-0 mt-[-40px] p-5 font-serif'>
      <h1 className='text-[#DAD3BE] border-b-4 border-b-[black] bg-[#254336] font-semibold md:text-[20px] text-[15px] mt-6 md:w-[20%] w-[30%] p-2 text-center rounded-lg'> Portfolio </h1>
      <h1 className='text-[30px] font-bold underline mt-2'>Research & Work</h1>
      <div className='flex flex-wrap w-[100%] border-b-2 border-b-black pb-5'>
        <div className='md:-[65%] w-[100%]'>
        <div className='mt-8 flex flex-row text-justify'>
      <GoDotFill className='text-[#1a565e] text-[65px] mt-[-20px] mr-2'/> 
      <p className='text-[18px]'>I have been involved in research activities even before I formally started my research career. Formally, I began my research in 2018 when I joined IIT Roorkee as a PhD scholar. As of now, I have been doing formal research for about four to five years. However, looking back to my B.Tech days in 2011, the projects I worked on also involved some level of research. Since 2011, I have always developed fascinating electronics and software projects and loved documenting them. </p>
      </div>
      <div className='mt-8 flex flex-row text-justify'>
      <GoDotFill className='text-[#1a565e] text-[65px] mt-[-20px] mr-2'/> 
      <p className='text-[18px]'>I attempted to write my first research paper during my Masters in 2015, based on my M.Tech thesis. However, due to a lack of guidance and time, I couldn’t shape it well or publish it. My first published paper was during my first job as an Assistant Professor at DIT Dehradun, but I later realized it wasn’t a good journal. I tried writing a few more papers but didn’t manage to publish a quality paper until my PhD. </p>
      </div>
      <div className='mt-8 flex flex-row text-justify'>
      <GoDotFill className='text-[#1a565e] text-[55px] mt-[-15px] mr-2'/> 
      <p className='text-[18px]'>During my PhD, I had a good environment and learned from my advisor and PhD seniors, including Dr. Jatin Bedi (now an Assistant Professor at Thapar University, Patiala, Punjab). I wrote multiple papers during this time, and since then, research and writing papers have become a significant part of my job.</p>
      </div>
      <div className='mt-8 flex flex-row text-justify'>
      <GoDotFill className='text-[#1a565e] text-[65px] mt-[-20px] mr-2'/> 
      <p className='text-[18px]'>One of my most appreciated projects is the KCC-CHAKSHU: Artificial Intelligence-based Analysis of the Kisan Call Center Dataset (https://kcc-chakshu.icar.gov.in/). Additionally, I have published multiple research papers, including a top publication in the Journal of Cleaner Production, which had an impact factor of 11.1 at the time of publication. My fields of interest include:</p>
      </div>
        </div>
        <div className='md:w-[30%] w-[100%] mx-auto mt-14'>
          <img src={samarth} className='rounded-2xl' />
        </div>
      </div>
      <h1 className='text-[30px] font-bold underline mt-8'>Research & Work</h1>
      <p className='mt-5 text-[18px] text-justify'>I have been involved in research activities even before I formally started my research career. Formally, I began my research in 2018 when I joined IIT Roorkee as a PhD scholar. As of now, I have been doing formal research for about four to five years. However, looking back to my B.Tech days in 2011, the projects I worked on also involved some level of research. Since 2011, I have always developed fascinating electronics and software projects and loved documenting them.</p>
      <p className='mt-4 text-[18px] text-justify'>I attempted to write my first research paper during my Masters in 2015, based on my M.Tech thesis. However, due to a lack of guidance and time, I couldn’t shape it well or publish it. My first published paper was during my first job as an Assistant Professor at DIT Dehradun, but I later realized it wasn’t a good journal. I tried writing a few more papers but didn’t manage to publish a quality paper until my PhD.</p>
    <p className='mt-4 text-[18px] text-justify'>During my PhD, I had a good environment and learned from my advisor and PhD seniors, including Dr. Jatin Bedi (now an Assistant Professor at Thapar University, Patiala, Punjab). I wrote multiple papers during this time, and since then, research and writing papers have become a significant part of my job.</p>
    <p className='mt-4 text-[18px] text-justify'>One of my most appreciated projects is the KCC-CHAKSHU: Artificial Intelligence-based Analysis of the Kisan Call Center Dataset (<a href='https://kcc-chakshu.icar.gov.in/' className='underline'>https://kcc-chakshu.icar.gov.in/</a>). Additionally, I have published multiple research papers, including a top publication in the Journal of Cleaner Production, which had an impact factor of 11.1 at the time of publication. My fields of interest include:</p>
    <div className='mt-10 border-b-2 border-b-[black] pb-10'>
    <div className='mt-8 flex flex-row text-justify'>
      <p className='text-[#1a565e] text-[25px] mt-[-8px] mr-2'> 1. </p>
      <p className='text-[18px] font-bold'>Kisan Call Data Analysis – Using AI to analyze Indian farmers’ helpline data, including millions of call records, to give policy recommendations.</p>
      </div>
      <div className='mt-4 flex flex-row text-justify'>
      <GoDot className='text-[#1a565e] text-[50px] mt-[-12px] mr-1'/> 
      <p className='text-[18px]'>Godara, Samarth, Durga Toshniwal, Ram Swaroop Bana, Deepak Singh, Jatin Bedi, Rajender Parsad, Jai Prakash Singh Dabas et al. “AgrIntel: Spatio-temporal profiling of nationwide plant-protection problems using helpline data.” Engineering Applications of Artificial Intelligence 117 (2023): 105555.</p>
      </div>
      <div className='mt-2 flex flex-row text-justify'>
      <GoDot className='text-[#1a565e] text-[40px] mt-[-6px] mr-1'/> 
      <p className='text-[18px]'>Godara, Samarth, Jatin Bedi, Rajender Parsad, Deepak Singh, Ram Swaroop Bana, and Sudeep Marwaha. “AgriResponse: A real-time agricultural query-response generation system for assisting nationwide farmers.” IEEE Access (2023).</p>
      </div>
      <div className='mt-2 flex flex-row text-justify'>
      <GoDot className='text-[#1a565e] text-[35px] mt-[-5px] mr-1'/> 
      <p className='text-[18px]'>Godara, Samarth, and Durga Toshniwal. “Sequential pattern mining combined multi-criteria decision-making for farmers’ queries characterization.” Computers and Electronics in Agriculture 173 (2020): 105448.</p>
      </div>
      <div className='mt-2 flex flex-row text-justify'>
      <GoDot className='text-[#1a565e] text-[30px] mt-[-2px] mr-1'/> 
      <p className='text-[18px]'>Godara, Samarth, and Durga Toshniwal. “Deep Learning-based query-count forecasting using farmers’ helpline data.” Computers and Electronics in Agriculture 196 (2022): 106875.</p>
      </div>
      <div className='mt-8 flex flex-row text-justify'>
      <p className='text-[#1a565e] text-[25px] mt-[-8px] mr-2'> 2. </p>
      <p className='text-[18px] font-bold'>AI-integrated Design of Experiment techniques – Combining Design of Experiments (DoE) with AI to improve model interpretation and precision.</p>
      </div>
      <div className='mt-4 flex flex-row text-justify'>
      <GoDot className='text-[#1a565e] text-[50px] mt-[-12px] mr-1'/> 
      <p className='text-[18px]'>Godara, Samarth, Rajender Parsad, Ram Swaroop Bana, Deepak Singh, G. Avinash, and Sudeep Marwaha. “DL-RSM: Deep learning-integrated Response Surface Methodology for positive and negative-ideal environmental conditions estimation for crop yield.” Journal of Cleaner Production 456 (2024): 142381.</p>
      </div>
      <div className='mt-8 flex flex-row text-justify'>
      <p className='text-[#1a565e] text-[25px] mt-[-8px] mr-2'> 3. </p>
      <p className='text-[18px] font-bold'>Bioinformatics Software Development – Creating smart AI-based software to solve various bioinformatics problems.</p>
      </div>
      <div className='mt-4 flex flex-row text-justify'>
      <GoDot className='text-[#1a565e] text-[50px] mt-[-12px] mr-1'/> 
      <p className='text-[18px]'>Godara, Samarth, Shbana Begam, Ramcharan Bhattacharya, Hukam C. Rawal, Anil Kumar Singh, Vijay Jangir, Sudeep Marwaha, and Rajender Parsad. “GSCIT: smart Hash Table-based mapping equipped genome sequence coverage inspection.” Functional & Integrative Genomics 24, no. 2 (2024): 36.</p>
      </div>
      <div className='mt-4 flex flex-row text-justify'>
      <GoDot className='text-[#1a565e] text-[33px] mt-[-4px] mr-1'/> 
      <p className='text-[18px]'>Begam, Shbana, Samarth Godara, Ramcharan Bhattacharya, Rajender Parsad, and Sudeep Marwaha. “SSRmine: Python-based Command-line Tool for Precise Genomic SSR Markers’ Extraction.”</p>
      </div>
      <div className='mt-4 flex flex-row text-justify'>
      <GoDot className='text-[#1a565e] text-[33px] mt-[-4px] mr-1'/> 
      <p className='text-[18px]'>Begam, Shbana, Samarth Godara, Ramcharan Bhattacharya, Rajender Parsad, and Sudeep Marwaha. “DEXA: A Python-based Tool for the Advanced Deciphering of Differential Gene Expression Patterns”.</p>
      </div>
      <div className='mt-8 flex flex-row text-justify'>
      <p className='text-[#1a565e] text-[25px] mt-[-8px] mr-2'> 4. </p>
      <p className='text-[18px] font-bold'>Probability Modelling – Developing AI-based models to estimate probabilities and reduce time needed for problem-solving.</p>
      </div>
      <div className='mt-4 flex flex-row text-justify'>
      <GoDot className='text-[#1a565e] text-[45px] mt-[-8px] mr-1'/> 
      <p className='text-[18px]'>Godara, Samarth, G. Avinash, Rajender Parsad, Sudeep Marwaha, Mukhtar Ahmad Faiz, and Ram Swaroop Bana. “Development and Assessment of SPM: A Sigmoid-Based Model for Probability Estimation in Non-Repetitive Unit Selection With Replacement.” IEEE Access (2024).</p>
      </div>
      <div className='mt-8 flex flex-row text-justify'>
      <p className='text-[#1a565e] text-[25px] mt-[-8px] mr-2'> 5. </p>
      <p className='text-[18px] font-bold'>Crop Modelling – Creating models to estimate crop yields using environmental and other factors.</p>
      </div>
      <div className='mt-4 flex flex-row text-justify'>
      <GoDot className='text-[#1a565e] text-[60px] mt-[-16px] mr-1'/> 
      <p className='text-[18px]'>Godara, Samarth, Pratap S. Birthal, G. Avinash, Mukhtar Ahmad Faiz, Ram Swaroop Bana, Abimanyu Jhajhria, Rajender Parsad, and Sudeep Marwaha. “Quantifying effects of climate change and farmers’ information demand on wheat yield in India: a deep learning approach with regional clustering.” Frontiers in Sustainable Food Systems 8 (2024): 1357201.</p>
      </div>
      <div className='mt-8 flex flex-row text-justify'>
      <p className='text-[#1a565e] text-[25px] mt-[-8px] mr-2'> 6. </p>
      <p className='text-[18px] font-bold'>Embedded Systems Development – Designing sensor-based systems, including both the circuits and software.</p>
      </div>
      <div className='mt-4 flex flex-row text-justify'>
      <GoDot className='text-[#1a565e] text-[20px] md:mr-1 mt-1'/> 
      <p className='text-[18px]'>Multiple Projects available at: <a href="https://www.instructables.com/member/Samartist/instructables/" className='underline'>Sam's Instructables</a></p>
      </div>
      <div className='mt-8 flex flex-row text-justify'>
      <p className='text-[#1a565e] text-[25px] mt-[-8px] mr-2'> 7. </p>
      <p className='text-[18px] font-bold'>Statistical Analysis – Analyzing agricultural experiment data to find insights and group factors based on their performance.</p>
      </div>
      <div className='mt-4 flex flex-row text-justify'>
      <GoDot className='text-[#1a565e] text-[60px] mt-[-15px] mr-1'/> 
      <p className='text-[18px]'>Bana, Ram Swaroop, Mukhtar Ahmad Faiz, Seema Sangwan, Anil K. Choudhary, Shanti D. Bamboriya, Samarth Godara, and Ravi Chandrabhan Nirmal. “Triple-zero tillage and system intensification lead to enhanced productivity, micronutrient biofortification and moisture-stress tolerance ability in chickpea in a pearlmillet-chickpea cropping system of semi-arid climate.” Scientific Reports 13, no. 1 (2023): 10226.</p>
      </div>
      <div className='mt-4 flex flex-row text-justify'>
      <GoDot className='text-[#1a565e] text-[50px] mt-[-12px] mr-1'/> 
      <p className='text-[18px]'>Yogi, Akshay Kumar, Ram Swaroop Bana, Shanti D. Bamboriya, Ram L. Choudhary, Alison M. Laing, Deepak Singh, Samarth Godara, Subhash Babu, and Amresh Chaudhary. “Foliar zinc fertilization improves yield, biofortification and nutrient-use efficiency of upland rice.” Nutrient Cycling in Agroecosystems 125, no. 3 (2023): 453-469.</p>
      </div>
      <div className='mt-4 flex flex-row text-justify'>
      <GoDot className='text-[#1a565e] text-[50px] mt-[-12px] mr-1'/> 
      <p className='text-[18px]'>Kumar, Kamlesh, Chiter M. Parihar, Hiranmay S. Nayak, Dipaka Ranjan Sena, Samarth Godara, Rajkumar Dhakar, Kiranmoy Patra et al. “Modeling maize growth and nitrogen dynamics using CERES-Maize (DSSAT) under diverse nitrogen management options in a conservation agriculture-based maize-wheat system.” Scientific Reports 14, no. 1 (2024): 317.</p>
      </div>
      <h1 className='text-[30px] font-bold mt-10'> More Information :</h1>
      <div className='mt-2 flex flex-row'>
      <GoDotFill className='text-[#1a565e] text-[20px] mt-1 mr-2'/> 
      <p className='text-[18px]'> Personal Homepage: <a href="https://samarthgodara.com/" className='underline'>https://samarthgodara.com/</a></p>
      </div>

      <hr/>
    </div>
    <div className='mt-10'>
    <h1 className='text-[30px] font-bold'> Project Associations:</h1>
    <div className='flex flex-wrap w-[100%] mt-5 border-b-2 border-b-black pb-10'>
      <div className='md:w-[45%] w-[100%] text-[18px]'>
        <div className='border-2 border-black'>
       <div className='w-[100%] flex flex-wrap border-b-2 border-b-black'>
        <div className='w-[30%] border-r-2 border-r-black text-center items-center justify-center'>
          <p className='hover:text-[#1a565e]'>Project Name:</p>
        </div>
        <div className='w-[65%] mx-auto text-justify'>
         <p className='hover:text-[#1a565e]'>Development of Artificial Intelligence integrated Big-Data based system for automatic query-response generation and analysis of Indian farmers’ queries</p>
        </div>
       </div>
       <div className='w-[100%] flex flex-wrap border-b-2 border-b-black'>
        <div className='w-[30%] border-r-2 border-r-black text-center items-center justify-center'>
          <p className='hover:text-[#1a565e]'>Project Team:</p>
        </div>
        <div className='w-[65%] mx-auto text-justify'>
         <p className='hover:text-[#1a565e]'><b>Mr. Samarth Godara</b> (P.I.), Mrs. Madhu, Ms. Sanchita Naha, Dr. J.P.S. Dabas</p>
        </div>
       </div>
       <div className='w-[100%] flex flex-wrap border-b-2 border-b-black'>
        <div className='w-[30%] border-r-2 border-r-black text-center items-center justify-center'>
          <p className='hover:text-[#1a565e]'>Date of Start:</p>
        </div>
        <div className='w-[65%] mx-auto text-justify'>
         <p className='hover:text-[#1a565e]'>10th Dec, 2021</p>
        </div>
       </div>
       <div className='w-[100%] flex flex-wrap border-b-2 border-b-black'>
        <div className='w-[30%] border-r-2 border-r-black text-center items-center justify-center'>
          <p className='hover:text-[#1a565e]'>Likely date of completion:</p>
        </div>
        <div className='w-[65%] mx-auto text-justify'>
         <p className='hover:text-[#1a565e]'>9th Dec, 2024</p>
        </div>
       </div>
       <div className='w-[100%] flex flex-wrap border-b-2 border-b-black border-t-2 border-t-black mt-2'>
        <div className='w-[30%] border-r-2 border-r-black text-center items-center justify-center'>
          <p className='hover:text-[#1a565e]'>Project Name:</p>
        </div>
        <div className='w-[65%] mx-auto text-justify'>
         <p className='hover:text-[#1a565e]'>Development of Artificial Intelligence based model and tool for genomic studies</p>
        </div>
       </div>
       <div className='w-[100%] flex flex-wrap border-b-2 border-b-black'>
        <div className='w-[30%] border-r-2 border-r-black text-center items-center justify-center'>
          <p className='hover:text-[#1a565e]'>Project Team:</p>
        </div>
        <div className='w-[65%] mx-auto text-justify'>
         <p className='hover:text-[#1a565e]'>Ms. Shbana Begam, NIPB,New Delhi Dr. Tapan Kumar Mondal, NIPB,New Delhi Mr. Yuvaraj Iyyapan, NIPB,New Delhi <b>Mr. Samarth Godara</b>, IASRI,New Delhi</p>
        </div>
       </div>
       <div className='w-[100%] flex flex-wrap border-b-2 border-b-black'>
        <div className='w-[30%] border-r-2 border-r-black text-center items-center justify-center'>
          <p className='hover:text-[#1a565e]'>Date of Start:</p>
        </div>
        <div className='w-[65%] mx-auto text-justify'>
         <p className='hover:text-[#1a565e]'>1st April, 2021</p>
        </div>
       </div>
       <div className='w-[100%] flex flex-wrap border-b-2 border-b-black'>
        <div className='w-[30%] border-r-2 border-r-black text-center items-center justify-center'>
          <p className='hover:text-[#1a565e]'>Likely date of completion:	</p>
        </div>
        <div className='w-[65%] mx-auto text-justify'>
         <p className='hover:text-[#1a565e]'>2nd April, 2026</p>
        </div>
       </div>
       <div className='w-[100%] flex flex-wrap border-b-2 border-b-black'>
        <div className='w-[30%] border-r-2 border-r-black text-center items-center justify-center'>
          <p className='hover:text-[#1a565e]'>Project Name:</p>
        </div>
        <div className='w-[65%] mx-auto text-justify'>
         <p className='hover:text-[#1a565e]'>1st April, 2021 Development of precision engineering technologies for agricultural input production management and value addition to ensure profitability, sustainability and environmental safety</p>
        </div>
       </div>
       <div className='w-[100%] flex flex-wrap border-b-2 border-b-black'>
        <div className='w-[30%] border-r-2 border-r-black text-center items-center justify-center'>
          <p className='hover:text-[#1a565e]'>Project Team:</p>
        </div>
        <div className='w-[65%] mx-auto text-justify'>
         <p className='hover:text-[#1a565e]'>Dr. P K Sharma, Division of Agricultural Engineering, ICAR-IARI, New Delhi (P.I.) <b>Mr. Samarth Godara </b>, Dr. Himadri Shekhar Roy (IASRI, New Delhi Team)</p>
        </div>
       </div>
       <div className='w-[100%] flex flex-wrap border-b-2 border-b-black'>
        <div className='w-[30%] border-r-2 border-r-black text-center items-center justify-center'>
          <p className='hover:text-[#1a565e]'>Date of Start:</p>
        </div>
        <div className='w-[65%] mx-auto text-justify'>
         <p className='hover:text-[#1a565e]'>21st Jan, 2023</p>
        </div>
       </div>
       <div className='w-[100%] flex flex-wrap'>
        <div className='w-[30%] border-r-2 border-r-black text-center items-center justify-center'>
          <p className='hover:text-[#1a565e]'>Likely date of completion:</p>
        </div>
        <div className='w-[65%] mx-auto text-justify'>
         <p className='hover:text-[#1a565e]'>31st March, 2026</p>
        </div>
       </div>
      </div>
      <div className='border-2 border-black mt-5'>
               <img src={tablelogo1}/>     
      </div>
      </div>
      <div className='md:w-[45%] w-[100%] mt-5 md:mt-0 mx-auto border-2 border-black text-[18px] h-[80%]'>
       <div className='w-[100%] flex flex-wrap border-b-2 border-b-black'>
        <div className='w-[30%] border-r-2 border-r-black text-center items-center justify-center'>
          <p className='hover:text-[#1a565e]'>Project Name:</p>
        </div>
        <div className='w-[65%] mx-auto text-justify'>
         <p className='hover:text-[#1a565e]'>Resilient Agricultural Education System (RAES)</p>
        </div>
       </div>
       <div className='w-[100%] flex flex-wrap border-b-2 border-b-black'>
        <div className='w-[30%] border-r-2 border-r-black text-center items-center justify-center'>
          <p className='hover:text-[#1a565e]'>Project Team:</p>
        </div>
        <div className='w-[65%] mx-auto text-justify'>
         <p className='hover:text-[#1a565e]'>Dr. Sudeep, Dr. Alka Arora, Dr. Anshu Bharadwaj, Dr. Ajit, Dr. V. Ramasubramanian, Dr. Shashi Dahiya, Sh. S. N. Islam Dr. Soumen Pal, Dr. Sanchita Naha, Ms. Madhu, Mr. Samarth Godra</p>
        </div>
       </div>
       <div className='w-[100%] flex flex-wrap border-b-2 border-b-black'>
        <div className='w-[30%] border-r-2 border-r-black text-center items-center justify-center'>
          <p className='hover:text-[#1a565e]'>Date of Start:</p>
        </div>
        <div className='w-[65%] mx-auto text-justify'>
         <p className='hover:text-[#1a565e]'>15-03-2021</p>
        </div>
       </div>
       <div className='w-[100%] flex flex-wrap border-b-2 border-b-black'>
        <div className='w-[30%] border-r-2 border-r-black text-center items-center justify-center'>
          <p className='hover:text-[#1a565e]'>Likely date of completion:</p>
        </div>
        <div className='w-[65%] mx-auto text-justify'>
         <p className='hover:text-[#1a565e]'>31-12-2024</p>
        </div>
       </div>
       <div className='w-[100%] flex flex-wrap border-b-2 border-b-black border-t-2 border-t-black mt-2'>
        <div className='w-[30%] border-r-2 border-r-black text-center items-center justify-center'>
          <p className='hover:text-[#1a565e]'>Project Name:</p>
        </div>
        <div className='w-[65%] mx-auto text-justify'>
         <p className='hover:text-[#1a565e]'>Model-assisted estimators using survey weighted artificial neural networks in complex surveys</p>
        </div>
       </div>
       <div className='w-[100%] flex flex-wrap border-b-2 border-b-black'>
        <div className='w-[30%] border-r-2 border-r-black text-center items-center justify-center'>
          <p className='hover:text-[#1a565e]'>Project Team:</p>
        </div>
        <div className='w-[65%] mx-auto text-justify'>
         <p className='hover:text-[#1a565e]'>Dr. Deepak Singh, Dr. Raju Kumar, Dr. Bharti <b>Mr. Samarth Godara</b>, IASRI, New Delhi</p>
        </div>
       </div>
       <div className='w-[100%] flex flex-wrap border-b-2 border-b-black'>
        <div className='w-[30%] border-r-2 border-r-black text-center items-center justify-center'>
          <p className='hover:text-[#1a565e]'>Date of Start:</p>
        </div>
        <div className='w-[65%] mx-auto text-justify'>
         <p className='hover:text-[#1a565e]'>27th June, 2023</p>
        </div>
       </div>
       <div className='w-[100%] flex flex-wrap border-b-2 border-b-black'>
        <div className='w-[30%] border-r-2 border-r-black text-center items-center justify-center'>
          <p className='hover:text-[#1a565e]'>Likely date of completion:	</p>
        </div>
        <div className='w-[65%] mx-auto text-justify'>
         <p className='hover:text-[#1a565e]'>28th December, 2026</p>
        </div>
       </div>
       <div className='w-[100%] flex flex-wrap border-b-2 border-b-black'>
        <div className='w-[30%] border-r-2 border-r-black text-center items-center justify-center'>
          <p className='hover:text-[#1a565e]'>Project Name:</p>
        </div>
        <div className='w-[65%] mx-auto text-justify'>
         <p className='hover:text-[#1a565e]'>Development of an intelligent system for pig live weight measurement</p>
        </div>
       </div>
       <div className='w-[100%] flex flex-wrap border-b-2 border-b-black'>
        <div className='w-[30%] border-r-2 border-r-black text-center items-center justify-center'>
          <p className='hover:text-[#1a565e]'>Project Team:</p>
        </div>
        <div className='w-[65%] mx-auto text-justify'>
         <p className='hover:text-[#1a565e]'>Mr. Ayon Tarafdar, ICAR-IVRI, Bareilly, Principal Investigator, Dr.Triveni Dutt, IVRI, Bareilly, Co Principal Investigator, Dr.Rupasi Tewari, IVRI, Bareilly, Co Principal Investigator,Mr.Chandan Kumar Deb, IASRI, New Delhi, Collaborating Center PI, Mr.Md. Ashraful Haque, IASRI, New Delhi, Co CCPI, <b>Mr.Samarth Godara</b>, IASRI, New Delhi, Co CCPI, Dr.Gyanendra Kumar Gaur, ICAR Headquarters, New Delhi, Collaborating Center PI,
Dr.Anuj Chauhan, IVRI, Bareilly, Co Principal Investigator, Dr.Mukesh Singh, IVRI, Bareilly, Co Principal Investigator.</p>
        </div>
       </div>
       <div className='w-[100%] flex flex-wrap border-b-2 border-b-black'>
        <div className='w-[30%] border-r-2 border-r-black text-center items-center justify-center'>
          <p className='hover:text-[#1a565e]'>Date of Start:</p>
        </div>
        <div className='w-[65%] mx-auto text-justify'>
         <p className='hover:text-[#1a565e]'>18-10-2020</p>
        </div>
       </div>
       <div className='w-[100%] flex flex-wrap'>
        <div className='w-[30%] border-r-2 border-r-black text-center items-center justify-center'>
          <p className='hover:text-[#1a565e]'>Likely date of completion:</p>
        </div>
        <div className='w-[65%] mx-auto text-justify'>
         <p className='hover:text-[#1a565e]'>17-10-2023</p>
        </div>
       </div>
      </div>
    </div>
    </div>
    <div className='mt-10'>
    <h1 className='text-[30px] font-bold'>Until now I have been involved in the development of multiple websites. Here are some of the ones I have worked on:</h1>
    <div className='mt-5'>
    <p className='text-[20px] font-bold'>KCC-CHAKSHU: Collated Historically Aggregated Knowledge-based System with Hypertext User-interface</p>
    <p className='mt-2 text-[18px]'>URL: <a href="https://kcc-chakshu.icar.gov.in/" className='underline'>https://kcc-chakshu.icar.gov.in/</a></p>
    <p className='mt-2 text-[18px] text-justify'>Development Team: Dr. Samarth Godara, Dr. Madhu, Dr. Sanchita Naha, Dr. J.P.S. Dabas, Dr. Rajender Parsad, Dr. Sudeep, Dr. R.S. Bana, Dr. Raju Kumar, Dr. Gograj Singh Jat, Dr. Abimanyu Jhajhria, Dr. Shashi Dahiya, Dr. Anshu Bharadwaj, Dr. Deepak Singh, Dr. Shbana Begam, Dr. Jatin Bedi.</p>
    <p className='mt-2 text-[18px]'>Technology Certification Date: July, 2023.</p>
    </div>
    <div className='mt-5'>
    <p className='text-[20px] font-bold'>AI-BHAROSA: AI-based Bharatiya Rotary-Systems’ Alignment Web portal</p>
    <p className='mt-2 text-[18px]'>URL: <a href="https://aibharosa.com/index.html" className='underline'>https://aibharosa.com/index.html</a></p>
    <p className='mt-2 text-[18px] text-justify'>Development Team: Dr. Samarth Godara, ICAR-IASRI, New Delhi; Mr. Madhur Behl, IIT Kharagpur, West Bengal; Dr. Rajender Parsad, ICAR-IASRI, New Delhi; Dr. Sudeep Marwaha, ICAR-IASRI, New Delhi; Dr. Gourab Dutta, IIT Kharagpur, West Bengal; Dr. Ram Swaroop Bana, ICAR-IARI, New Delhi; Dr. Shruti Godara, ICFRE, Forest Research Institute, Dehradun, Uttarakhand; Dr. Deepak Singh, ICAR-IARI, New Delhi; Dr. Shbana Begam, ICAR-NIPB, New Delhi; Dr. Jatin Bedi, Thapar Institute of Engineering and Technology, Punjab; Mr. Vijay Jangir, CGC Landran, Chandigarh; Tarun Bhatia, Sehjivi Digital Solutions, Sri Ganganagar, Rajasthan; Arun Grover, Sehjivi Digital Solutions, Sri Ganganagar, Rajasthan</p>
    <p className='mt-2 text-[18px]'>Publication Date: November, 2023.</p>
    </div>
    <div className='mt-5'>
    <p className='text-[20px] font-bold'>GARUD: Genetically Aggregated Rice User-interface Database</p>
    <p className='mt-2 text-[18px]'>URL: <a href="https://rice-garud.icar-web.com/index.php" className='underline'>https://rice-garud.icar-web.com/index.php</a></p>
    <p className='mt-2 text-[18px] text-justify'>Development Team: Dr. Shbana Begam (PI), Scientist ICAR-NIPB, New Delhi, India; Dr. Yuvaraj I, Scientist ICAR-NIPB, New Delhi, India; Dr. Ramcharan Bhattacharya, Director, ICAR-NIPB, New Delhi, India; Dr. D. K. Yadava, AGD Seed Science, New Delhi, India; Dr. Tapan Kumar Mondal, Principal Scientist, ICAR-NIPB, New Delhi, India; Dr. R. S. Bana, Senior Scientist, ICAR-IARI, New Delhi, India; Dr. Samarth Godara, Scientist, ICAR-IASRI, New Delhi, India; Dr. Jyotsana Tilgam, Scientist, ICAR-NBAIM, Mau, U.P., India</p>
    <p className='mt-2 text-[18px]'>Publication Date: November, 2023.</p>
    </div>
    <div className='mt-5'>
    <p className='text-[20px] font-bold'>MustardFamilyExplorer</p>
    <p className='mt-2 text-[18px]'>URL: <a href="https://mustardfamilyexplorer.icar-web.com/index.html" className='underline'>https://mustardfamilyexplorer.icar-web.com/index.html</a></p>
    <p className='mt-2 text-[18px] text-justify'>Development Team: Dr. Shbana Begam Scientist ICAR-NIPB, New Delhi; Dr. Samarth Godara Scientist, ICAR-IASRI, New Delhi, India; Dr. Ramcharan Bhattacharya Director, ICAR-NIPB, New Delhi-India; Dr. Tapan Kumar Mondal Principal Scientist, ICAR-NIPB, New Delhi, India; Dr. Ram Swaroop Bana Senior Scientist, ICAR-IARI, New Delhi, India; Dr. Mahesh Rao Scientist, ICAR-NIPB, New Delhi, India</p>
    <p className='mt-2 text-[18px]'>Publication Date: November, 2023.</p>
    </div>
    </div>
    </div>
  );
}

export default Portfolio;
