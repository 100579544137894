import React, { useState } from 'react';
import samarthlogo from '../images/samarth-logo.png';

const Layout = ({ scrollToSection }) => {
  const [nav, setNav] = useState(false);
  const [activeSection, setActiveSection] = useState('home'); // Track the active section

  const handleNav = () => {
    setNav(!nav);
  };

  const handleSectionClick = (section) => {
    setActiveSection(section); // Set the active section
    scrollToSection(section);
  };

  return (
    <div className='fixed top-0 z-10'>
      {/* Navbar code */}
      <div className='bg-[#254336] text-[#DAD3BE] border-2 border-[#DAD3BE] md:w-[100%] mx-auto flex flex-wrap text-center items-center'>
        <div className='w-[40%]'>
          <img className='md:w-[100%] w-[500px]' src={samarthlogo} />
        </div>
        <div className='w-[60%] text-center md:text-[30px] text-[15px] md:p-3 font-semibold font-serif'>
          <h1> Agricultural Scientist at the Indian Council of Agricultural Research.</h1>
        </div>
      </div>
      <nav className="fixed top-15 md:top-50 z-10 w-full font-serif">
        <div className="flex flex-wrap w-[100%] mx-auto">
          <ul className="flex p-4 w-[100%] text-[#DAD3BE] bg-[#6B8A7A] mx-auto">
            <li className='mx-auto'>
              <button
                onClick={() => handleSectionClick('home')}
                className={`font-semibold md:text-[16px] text-[12px] p-2 hover:bg-[#DAD3BE] hover:text-[#254336] hover:rounded-lg ${activeSection === 'home' ? 'bg-[#DAD3BE] text-[#254336] rounded-lg' : ''}`}
              >
                Home
              </button>
            </li>
            <li className='mx-auto'>
              <button
                onClick={() => handleSectionClick('about')}
                className={`font-semibold md:text-[16px] text-[12px] p-2 hover:text-[#254336] hover:bg-[#DAD3BE] hover:rounded-lg ${activeSection === 'about' ? 'bg-[#DAD3BE] text-[#254336] rounded-lg' : ''}`}
              >
                About Me
              </button>
            </li>
            <li className='mx-auto'>
              <button
                onClick={() => handleSectionClick('resume')}
                className={`font-semibold md:text-[16px] text-[12px] p-2 hover:text-[#254336] hover:bg-[#DAD3BE] hover:rounded-lg ${activeSection === 'resume' ? 'text-[#254336] bg-[#DAD3BE] rounded-lg' : ''}`}
              >
                Resume
              </button>
            </li>
            <li className='mx-auto'>
              <button
                onClick={() => handleSectionClick('portfolio')}
                className={`font-semibold md:text-[16px] text-[12px] p-2 hover:text-[#254336] hover:bg-[#DAD3BE] hover:rounded-lg ${activeSection === 'portfolio' ? 'text-[#254336] bg-[#DAD3BE] rounded-lg' : ''}`}
              >
                Portfolio
              </button>
            </li>
            <li className='mx-auto'>
              <button
                onClick={() => handleSectionClick('contact')}
                className={`font-semibold md:text-[16px] text-[12px] p-2 hover:text-[#254336] hover:bg-[#DAD3BE] hover:rounded-lg ${activeSection === 'contact' ? 'text-[#254336] bg-[#DAD3BE] rounded-lg' : ''}`}
              >
                Contact
              </button>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Layout;
