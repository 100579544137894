import React from 'react';

function About() {
  return (
    <div className='mx-auto md:w-[90%] w-[100%] p-5 font-serif mb-10 md:mt-0 mt-[-40px]'>
      <div className='flex flex-wrap md:w-[90%] w-[100%]'>
      <div className="md:w-[60%] w-[100%] text-justify">
      <h1 className='text-[#DAD3BE] border-b-4 border-b-black bg-[#254336] font-semibold md:text-[20px] text-[15px] mt-6 md:w-[20%] w-[30%] p-2 text-center rounded-lg'> About Me</h1>
      <h1 className='text-[30px] font-bold underline mt-2'>Agriculture Scientist</h1>
      <p className='text-[18px] font-semibold mt-2' >Indian Agricultural Statistics Research Institute, New Delhi, India</p>
      <p className='mt-2 text-[18px]'>I was a scientist at the Indian Council of Agricultural Research - Indian Agricultural Statistics Research Institute, New Delhi, India, where I got to explore AI and Statistical thinking on a daily basis. I have been working here for the past four and a half years, and it's been about six months since I finished my PhD at the Indian Institute of Technology, Roorkee, Uttarakhand, India. At this point in my life, I'm trying to pursue the flow of life instead of pushing myself towards a specific goal. </p>
      </div>
      <div className="md:w-[28%] text-[18px] w-[100%] bg-[#254336] text-[#DAD3BE] mx-auto rounded-2xl p-5 mt-5 items-center">
      <h1> Name </h1>
      <h1 className='font-bold'> Samarth Godara</h1>
      <hr/>
      <h1 className='mt-4'> Birthday </h1>
      <h1 className='font-bold'> April 22, 1990</h1>
      <hr/>
      <h1 className='mt-4'> Mail </h1>
      <h1 className='font-bold'> samarth@samarthgodara.com</h1>
      <hr/>
      <h1 className='mt-4'> Nationality </h1>
      <h1 className='font-bold'> Indian</h1>
      </div>
      </div>
    </div>
  );
}

export default About;
