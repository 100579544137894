import React from 'react';

function Contact() {
  return (
    <div className='mx-auto md:w-[90%] w-[100%] p-5 font-serif mt-[-180px]'>
      <h1 className='text-[#DAD3BE] border-b-4 border-b-[black] bg-[#254336] font-semibold md:text-[20px] text-[15px] mt-6 md:w-[20%] w-[40%] p-2 text-center rounded-lg'>  Contact Me </h1>
      <h1 className='text-[30px] font-bold underline mt-2'>Contact me to get your work done</h1>
      <p className='text-[18px]  mt-4'> Email:- <b>samarth@samarthgodara.com</b> </p>
    </div>
  );
}

export default Contact;
