import React from 'react';
import { IoBookSharp } from "react-icons/io5";
import { FaUserCheck } from "react-icons/fa";
import resumephoto from '../images/resume photo.webp';

function Resume() {
  return (
    <div className='mx-auto md:w-[90%] w-[100%] p-5 font-serif md:mt-0 mt-[-40px]'>
      <h1 className='text-[#DAD3BE] border-b-4 border-b-black bg-[#254336] font-semibold md:text-[20px] text-[15px] mt-6 md:w-[20%] w-[30%] p-2 text-center rounded-lg'> Resume </h1>
      <h1 className='text-[30px] font-bold underline mt-2'>Combination of Skill & Experience</h1>
      <div className='flex flex-wrap md:w-[95%] w-[100%] mx-auto mt-5'>
        <div className='md:w-[43%] p-5 w-[100%]'>
        <h1 className='text-[30px] font-bold mt-2 flex flex-wrap items-center gap-2'><IoBookSharp />Education</h1>
        <div className='flex flex-wrap w-[100%] mt-5'>
          <div className='md:w-[70%] w-[60%]'>
          <h1 className='font-extrabold text-[24px]'> Bachelor’s Degree</h1>
          <p className='text-[18px]'>Sacred Heart Convent School</p>
          </div>
          <div className='md:w-[30%] w-[40%] mt-2 '>
         <h1 className='border-2 border-black rounded-2xl p-2 text-center justify-center'> 2010 - 2013 </h1>
          </div>
        </div>
        <p className='text-[18px] mt-2 text-justify'> did most of my schooling at Sacred Heart Convent School in Sriganganagar, Rajasthan, India. In school, I was one of the naughtiest students, maybe even a bit of a bully.</p>
        <div className='flex flex-wrap w-[100%] mt-10'>
          <div className='md:w-[70%] w-[60%]'>
          <h1 className='font-extrabold text-[24px]'> Gate Coaching</h1>
          <p className='text-[18px]'>National Institute of Technology Jalandhar, Punjab</p>
          </div>
          <div className='md:w-[30%] w-[40%] mt-5'>
         <h1 className='border-2 border-black rounded-2xl p-2 text-center justify-center'> 2013 - 2016 </h1>
          </div>
        </div>
        <p className='text-[18px] mt-2 text-justify'> With guidance from my teachers, including Er. Arun Grover, I went to New Delhi, India, in 2013 for GATE exam coaching. This exam is for entrance into M.Tech programs at top engineering colleges in India. </p>
        <div className='flex flex-wrap w-[100%] mt-10'>
          <div className='md:w-[70%] w-[60%]'>
          <h1 className='font-extrabold text-[24px]'>PhD & Job</h1>
          <p className='text-[18px]'>the Indian Council of Agricultural Research (ICAR).</p>
          </div>
          <div className='md:w-[30%] w-[40%] mt-8'>
         <h1 className='border-2 border-black rounded-2xl p-2 text-center justify-center'> 2018 - 2023 </h1>
          </div>
        </div>
        <p className='text-[18px] mt-2 text-justify'> I found out that I was selected for a Scientist position at the Indian Council of Agricultural Research (ICAR). I joined ICAR-IASRI in New Delhi in 2020 and completed my PhD alongside my job, finishing it in 2023. </p>
        </div>
        <div className='md:w-[52%] w-[100%] md:ms-10 p-5'>
        <h1 className='text-[30px] font-bold mt-2 flex flex-wrap items-center gap-2'><FaUserCheck />Experience</h1>
        <div className='flex flex-wrap w-[100%] md:mt-8 mt-4'>
          <div className='md:w-[70%] w-[55%]'>
          <h1 className='font-extrabold text-[24px]'>Agriculture Scientist</h1>
          <p className='text-[18px] text-justify'>Indian Agricultural Statistics Research Institute, New Delhi, India</p>
          </div>
          <div className='md:w-[30%] w-[45%] md:mt-2 mt-6'>
         <h1 className='border-2 border-black rounded-2xl p-2 text-center justify-center'>2020 - running </h1>
          </div>
        </div>
        <p className='text-[18px] mt-2 text-justify'> In my job, I explore various fields like Artificial Intelligence, Statistics, Bioinformatics, Embedded Systems, and more. Apart from academics, which I see as an art form, I also enjoy making sketches, especially portraits, playing the guitar and ukulele, and being drawn to almost all forms of art. For me, art is more important than logic. </p>
        <div className='mt-10'>
          <img src={resumephoto}/>
        </div>
          </div>
      </div>
    </div>
  );
}

export default Resume;
