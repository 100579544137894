import React from 'react';

function Home() {
  return (
    <div className='md:mt-[280px] mt-[130px] mx-auto md:w-[90%] w-[100%] p-5 font-serif'>
      <h1 className='text-[30px] font-bold underline'>Samarth Godara </h1>
      <p className='text-[20px]'>Agriculture scientist at the Indian Council of Agricultural Research - Indian Agricultural Statistics Research Institute, New Delhi, India</p>
    </div>
  );
}

export default Home;
