import React, { useState, useRef, useEffect } from 'react';
import Layout from './Layout';
import HomeSection from '../pages/home';
import AboutSection from '../pages/about';
import Resume from '../pages/resume';
import Portfolio from '../pages/portfolio';
import Contact from '../pages/contact';

const HomePage = () => {
  const [activeSection, setActiveSection] = useState('home');
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const resumeRef = useRef(null);
  const portfolioRef = useRef(null);
  const contactRef = useRef(null);

  const scrollToSection = (section) => {
    if (section === 'home') {
      homeRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (section === 'about') {
      aboutRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (section === 'resume') {
      resumeRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (section === 'portfolio') {
      portfolioRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (section === 'contact') {
      contactRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '-200px 0px 0px 0px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, options);

    if (homeRef.current) observer.observe(homeRef.current);
    if (aboutRef.current) observer.observe(aboutRef.current);
    if (resumeRef.current) observer.observe(resumeRef.current);
    if (portfolioRef.current) observer.observe(portfolioRef.current);
    if (contactRef.current) observer.observe(contactRef.current);

    return () => {
      if (homeRef.current) observer.unobserve(homeRef.current);
      if (aboutRef.current) observer.unobserve(aboutRef.current);
      if (resumeRef.current) observer.unobserve(resumeRef.current);
      if (portfolioRef.current) observer.unobserve(portfolioRef.current);
      if (contactRef.current) observer.unobserve(contactRef.current);
    };
  }, []);

  return (
    <div>
      <Layout scrollToSection={scrollToSection} activeSection={activeSection} />
      <div ref={homeRef} id="home" className='-mt-[200px] pt-[200px]'>
        <HomeSection />
      </div>
      <div ref={aboutRef} id="about" className='-mt-[200px] pt-[200px]'>
        <AboutSection />
      </div>
      <div ref={resumeRef} id="resume" className='-mt-[200px] pt-[200px]'>
        <Resume />
      </div>
      <div ref={portfolioRef} id="portfolio" className='-mt-[200px] pt-[200px] pb-[150px]'>
        <Portfolio />
      </div>
      <div ref={contactRef} id="contact" className='-mt-[200px] pt-[200px] pb-[100px]'>
        <Contact />
      </div>
    </div>
  );
};

export default HomePage;
